import axios from 'axios';
// let authToken =localStorage.getItem('Auth_Token') || sessionStorage.getItem('Auth_Token')
// axios.defaults.headers.common['token'] = authToken;
const joompaApi = axios.create({
    // baseURL: 'https://joompa-0bka.onrender.com',
    baseURL: 'https://staging-api.letsjoompa.com',
    headers: {
      'Content-Type': 'application/json',
    },
  });

export default joompaApi;